import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color, typography } from "../../../shared/style";
import { Text } from "../../../components/Text/Text";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../SpaceConntainer/RichtextHelper/CustomText";
import Fade from "react-reveal/Fade";
import { isMobile } from '../../../hooks/checkMobile';
const ItemList = ({ item }) => {
  return (
    <WrapperItem>
      <Fade bottom cascade disabled={isMobile} distance="10px">
        <Item>
          <Icon src={item?.icon?.url} />
          <TextExtend color={color.dark.dark100} font="Oxygen">
            {item?.description?.text}
          </TextExtend>
        </Item>
      </Fade>
    </WrapperItem>
  );
};
export const Why = (props) => {
  const { items, primary } = props || {};
  if (!items || !primary) return null;
  return (
    <Wrapper>
      <Fade bottom cascade duration={2000} disabled={isMobile} distance="10px">
        <Contain>
          <Left>
            <LeftWrapper>
              <WrapperText>
                <Title
                  color={color.primary.blue}
                  tag="h3"
                  capital="uppercase"
                  font="Alternate Gothic No1 D"
                  lineHeight={90}
                  weight={typography.weight.regular1}
                >
                  <RichText
                    render={primary?.title?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Title>
              </WrapperText>
              <List>
                {items?.map((item, i) => (
                  <ItemList key={i} item={item} />
                ))}
              </List>
            </LeftWrapper>
          </Left>
          <Right>
            <Fade disabled={isMobile}>
              <Image src={primary?.image?.url} alt={primary?.image?.alt} />
            </Fade>
          </Right>
        </Contain>
      </Fade>
    </Wrapper>
  );
};
const List = styled.div``;
const LeftWrapper = styled.div`
  background-color: ${color.primary.white};
  padding: 50px;
  @media (max-width: 1000px) {
    padding: 15px;
  }
`;
const WrapperText = styled.div`
  padding: 24px;
  @media (max-width: 650px) {
    padding: 0;
    padding-top: 20px;
    margin-right: 23px;
    margin-bottom: 32px;
  }
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;
const Icon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 48px;
  max-height: 48px;
  margin-top: -10px;
  margin-right: 20px;
`;
const TextExtend = styled(Text)`
  @media (max-width: 650px) {
    margin-left: 20px;
    line-height: 26px;
  }
`;
const WrapperItem = styled.div`
  border-top: 1px solid ${color.primary.blue10};
  padding: 24px;
  @media (max-width: 450px) {
    padding: 24px 0;
  }
`;
const Title = styled(Text)`
  width: 80%;
  padding: 24px 0;
  span {
    font-weight: 500;
  }
  @media (max-width: 1200px) and (min-width: 1001px) {
    span {
      font-size: 82px !important;
      line-height: 82px;
    }
  }
  @media (max-width: 650px) {
    line-height: 60px;
  }
`;
const Left = styled.div`
  width: 51.5%;
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 48.5%;
  .react-reveal {
    height: 100%;
  }
  @media (max-width: 1200px) {
    /* margin-left: -25px; */
  }
  @media (max-width: 650px) {
    width: 100%;
    background: white;
  }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const Contain = styled.div`
  position: relative;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  @media (max-width: 1300px) {
    padding: 20px;
    box-sizing: border-box;
  }
  @media (max-width: 650px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;
const Wrapper = styled.div`
  .react-reveal {
    margin: 0 auto;
  }
  margin-top: 150px !important;
  height: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  margin: 0 auto;
  flex-wrap: wrap;
  background: linear-gradient(to bottom, #ffff 28%, #e9ecf1 28%);
  padding-bottom: 165px !important;
  @media (max-width: 650px) {
    padding: 20px;
    background: linear-gradient(to bottom, #ffff 20%, #e9ecf1 20%);
    flex-direction: column;
    margin-top: 95px !important;
    padding-bottom: 80px !important;
  }
`;

Why.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      readmore: PropTypes.shape({
        url: PropTypes.string,
      }),
      date: PropTypes.string,
    })
  ),
  primary: PropTypes.shape({
    read_all_article: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
  }),
};

Why.defaultProps = {};
