import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button } from "../../../components/Button/Button";
import { Text } from "../../../components/Text/Text";
import { color, spacing, typography } from "../../../shared/style";
import htmlSerializer from "../../SpaceConntainer/RichtextHelper/CustomText";
import Fade from "react-reveal/Fade";
import { isMobile } from "../../../hooks/checkMobile";
import { redirectPage } from "../../../hooks/redirect";

export const DraperEnterpreneurialNetwork = ({ data }) => {
  if (!data) return null;
  return (
    <Wrapper>
      <WrapperBanner>
        <Banner src={data?.image?.url}></Banner>
      </WrapperBanner>
      <WrapperContent>
        <WrapperTitle>
          <Fade distance="10px" bottom disabled={isMobile}>
            <Logo src={data?.den_logo?.url}></Logo>
          </Fade>
        </WrapperTitle>
        <Infor>
          <Fade distance="10px" bottom disabled={isMobile}>
            <Description
              size={typography.size.s3}
              lineHeight={26}
              font={typography.type.primary}
              color={color.primary.white}
            >
              <RichText
                render={data?.description?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Description>
            <WrapperButton>
              <ButtonDen
                type="tertiary"
                onClick={() =>
                  redirectPage({
                    url: data?.button_link?.url,
                    type: data?.button_link?.type,
                    link_type: data?.button_link?.link_type,
                  })
                }
              >
                {data?.button_label?.text}
              </ButtonDen>
            </WrapperButton>
          </Fade>
        </Infor>
      </WrapperContent>
    </Wrapper>
  );
};

DraperEnterpreneurialNetwork.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    logo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};
const WrapperButton = styled.div`
  @media (max-width: 1000px) and (min-width: 601px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperBanner = styled.div`
  width: 100%;
  height: 670px;
  max-width: 1500px;
  @media (max-width: 600px) {
    height: 320px;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 1240px;
  width: 100%;
  height: fit-content;
  padding: 70px 0;
  background-color: ${color.primary.blue};
  margin-top: -221px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 1240px) and (min-width: 601px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    padding: 0;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -100px;
  }
`;

const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const WrapperTitle = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
    .react-reveal {
      max-height: 95px;
    }
  }
`;

const Logo = styled.img``;

const Infor = styled.div`
  width: 40%;
  padding-right: 120px;
  @media (max-width: 1000px) {
    padding-right: 20px;
    margin: 20px 0;
    width: 70%;
    text-align: center;
  }
  @media (max-width: 650px) {
    text-align: center;
    width: 80%;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 60px;
  }
`;

const Description = styled(Text)``;

const ButtonDen = styled(Button)`
  /* &:hover {
    border: 
  } */
  margin-top: 32px;
  color: ${color.primary.blue};
  border-radius: ${spacing.borderRadius.Larger}px;
  font-family: ${typography.type.primary};
  /* border: none; */
  height: 65px;
  padding: 20px 45px;
`;
