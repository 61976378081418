import React, { useMemo } from "react";
import { LocationMap } from "../../../components/Map/LocationMap";
import styled from "styled-components";
import { Text } from "../../../components/Text/Text";
import { color, spacing, typography } from "../../../shared/style";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import htmlSerializer from "../../../containers/SpaceConntainer/RichtextHelper/CustomText";
import Fade from "react-reveal/Fade";
import { isMobile } from "../../../hooks/checkMobile";
export const LocationMapSection = ({ geolocations, data }) => {
  const geo = useMemo(() => {
    return geolocations?.map((item) => {
      //return item?.location?.document?.data?.geo_location;
      const ite = item?.location?.document?.data;
      return {
        geolocation: ite?.geo_location,
        city: ite?.city,
        country: ite?.country,
        email: ite?.body5[0]?.primary?.email,
        phone: ite?.body5[0]?.primary?.phone_number,
      };
    });
  }, [geolocations]);
  if (!geolocations || !data) return null;

  return (
    <Wrapper>
      <LeftSide>
        <Div>
          <TextExtend
            capital="uppercase"
            color={color.primary.blue}
            tag="h3"
            weight={typography.weight.regular1}
          >
            <Fade left duration={1000} disabled={isMobile} distance="10px">
              <RichText
                render={data?.title?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Fade>
          </TextExtend>
        </Div>
        <TextExtend
          tag="p"
          font="Oxygen"
          lineHeight={26}
          color={color.netraul.black100}
        >
          {/* <Fade left duration={1000} disabled={isMobile} distance="10px">
          {data?.primary?.description?.text ||
            "DSH's locations are here to foster collisions between entrepreneurs and create extraordinary companies along the way!"}
          </Fade> */}
          <RichText
            render={data?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </TextExtend>
      </LeftSide>
      <RightSide>
        <Fade right disabled={isMobile} distance="10px">
          <LocationMap geolocations={geo} page="home" />
        </Fade>
      </RightSide>
    </Wrapper>
  );
};
const TextExtend = styled(Text)`
  width: 345px;
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const Div = styled.div`
  margin-bottom: 22px;
  @media (max-width: 650px) {
    margin-bottom: 34px;
  }
`;
const LeftSide = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  padding-left: 108px;
  justify-content: center;
  @media (max-width: 1500px) and (min-width: 1058px) {
  }
  @media (max-width: 1057px) and (min-width: 650px) {
    align-items: flex-start;
    margin-left: 0;
    padding-left: 40px;
  }
  @media (max-width: 650px) {
    width: 100%;
    align-items: flex-start;
    text-align: start;
    margin-left: 0;
    padding-left: 0;
  }
`;
const RightSide = styled.div`
  height: 373px;
  width: 50%;
  @media (max-width: 650px) {
    /* display: none; */
    height: max-content;
    width: 100%;
  }
`;
const Wrapper = styled.div`
  max-width: 1274px;
  margin: 0 auto;
  margin-top: 62px;
  display: flex;
  flex-direction: row;
  @media (max-width: 650px) {
    flex-direction: column;
    padding: 0 20px;
    margin-top: 50px;
  }
`;
LocationMapSection.propTypes = {
  geolocations: PropTypes.arrayOf(
    PropTypes.shape({
      geolocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      label: PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
      }),
      link: PropTypes.shape({
        link_type: PropTypes.string,
      }),
    })
  ),
};

LocationMapSection.defaultProps = {
  geolocations: [
    {
      geolocation: {
        latitude: 16,
        longitude: 108.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da Nang Hello",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 45.5604128,
        longitude: 77.6697583,
      },
      label: {
        type: "heading1",
        text: "An do",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: -34.7678813,
        longitude: 19.799,
      },
      label: {
        type: "heading1",
        text: "Chau phi",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 22.01648534731439,
        longitude: -79.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 42.91648534731439,
        longitude: 19.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 45.31648534731439,
        longitude: 108.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
  ],
};
