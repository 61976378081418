import React from "react";
import PropTypes from "prop-types";
import { ThreeInOneSection } from "../../../components/ThreeInOneSection";
export const Ventures = (props) => {
  const { primary, type } = props;
  if (!primary || !type) return null;
  return <ThreeInOneSection primary={primary} type={type} />;
};
const TYPE = {
  ventures: "ventures",
  academy: "academy",
  service: "service"
}
Ventures.propTypes = {
  primary: PropTypes.shape({
    content: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      )
    }),
    title: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      )
    }),
    description: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      )
    }),
    image: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  type: PropTypes.oneOf(Object.values(TYPE))
};

Ventures.defaultProps = {};
