import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color, typography } from "../../../shared/style";
import { Text } from "../../../components/Text/Text";
import { Icons } from "@storybook/components";
import { RichText } from "prismic-reactjs";
import { Link } from "../../../components/Link/Link";
import htmlSerializer from "../../SpaceConntainer/RichtextHelper/CustomText";
import Fade from "react-reveal/Fade";
import useApp from "../../../contexts/context";
import { navigate } from "gatsby";
const Item = ({ item }) => {
  const { isMobile } = useApp();
  const redirectPage = (url) => {
    navigate(`/${url}`);
  };
  return (
    <WrapperItem>
      <Fade distance="10px" disabled={isMobile}>
        <ContainerItem>
          <ImageItem src={item?.data?.image?.url} />
          <StartDate>{item?.data?.release_date}</StartDate>
          <WrapperTitle>
            <Title
              color={color.primary.white}
              tag="h5"
              capital="uppercase"
              size={typography.size.l1}
              font="Alternate Gothic No1 D"
              weight={500}
            >
              {/* <RichText
                render={item?.data?.title?.raw}
                htmlSerializer={htmlSerializer}
              /> */}
              {item?.data?.title?.text}
            </Title>
            <Btn>
              <BtnReadMore to={item?.readmore?.url} size={16} lineHeight={26} 
                  onClick={() => redirectPage(item?.uid)}>
                Read more
              </BtnReadMore>
            </Btn>
          </WrapperTitle>
        </ContainerItem>
      </Fade>
    </WrapperItem>
  );
};
const SeeMore = ({ position, primary }) => {
  return (
    <TextLink
      to={"blog"}
      font="Oxygen"
      position={position}
      color={color.primary.white}
      size={typography.size.s4}
      lineHeight={28}
    >
      {position === "head" ? (
        <>
          Read more Stories and News <Icon icon="arrowrightalt" />
        </>
      ) : (
        <>Read more Stories and News</>
      )}
    </TextLink>
  );
};
export const Stories = ({data}) => {
  const { items, primary } = data || {};
  const redirectPage = (url) => {
    navigate(`${url}`);
  };
  if (!items || !primary) return null;
  return (
    <Wrapper>
      <Contain>
        <Head>
          <Text
            tag="h3"
            color={color.primary.white}
            weight={typography.weight.regular1}
            capital="uppercase"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
            {/* Stories and News */}
          </Text>
          <SeeMore position="head" primary={primary} />
        </Head>
        <Divider />
        <WrapperContent>
          {items?.map((item, i) => (
            <Item key={i} item={item?.blog?.document} />
          ))}
        </WrapperContent>
        <SeeMore
          position="foot"
          primary={primary}
          onClick={() => redirectPage("#")}
        />
      </Contain>
    </Wrapper>
  );
};
const WrapperTitle = styled.div`
  
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`
const Contain = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
  @media (max-width: 650px) {
    padding: 20px;
    box-sizing: border-box;
  }
`;
const BtnReadMore = styled(Link)`
  color: ${color.netraul.black100};
  background-color: ${color.secondary.yellowcream};
  border: none;
  height: 40px;
  padding: 7px 35px;
  border-radius: 25px;
  /* opacity: 0.5; */
  font-family: ${typography.type.primary};
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
`;
const Btn = styled.div`
  @media (max-width: 650px) {
    margin-top: 10px;
    position: inherit;
  }
`;
const StartDate = styled.div`
  color: ${color.primary.blue20};
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 16px;
  @media (max-width: 650px) {
    margin-bottom: 10px;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 650px) {
    flex-direction: column;
    div:first-child {
      padding-top: 0px;
    }
  }
`;
const ContainerItem = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const Title = styled(Text)`
  padding: 10px 0;
  div {
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    font-size: 24px !important;

  }
  @media (max-width: 650px) {
    font-size: 30px !important;
    line-height: 36px !important;
    div {
      margin-bottom: 20px;
    }
  }
`;
const ImageItem = styled.img`
  object-fit: cover;
  margin-bottom: 30px;
  width: 100%;
  /* max-height: 390px; */
  height: 390px;
  @media (max-width: 650px) {
    max-height: 610px;
  }
`;
const WrapperItem = styled.div`
.react-reveal {
  height: 100%;
}
  padding: 17.5px;
  /* &:first-child {
    margin-right: 17.5px;
    margin-left: 0;
  }
  &:last-child {
    margin-left: 17.5px;
    margin-right: 0;
  } */
  cursor: pointer;
  width: calc(100% / 3);
  box-sizing: border-box;
  @media (max-width: 650px) {
    padding: 17.5px 0;
    width: 100%;
    margin-bottom: 30px;
    margin: 0px !important;
  }
  transform: translateY(0);
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  &:hover {
    /* transform: translateY(-10px); */
    box-shadow: 0 25px 55px rgb(0 0 0 / 22%);
    /* transition: transform .65s cubic-bezier(.05,.2,.1,1),box-shadow .65s cubic-bezier(.05,.2,.1,1); */
  }
`;
const Divider = styled.div`
  width: 100%;
  border-top: 10px solid ${color.primary.blue05};
  opacity: 0.05;
  margin: 45px 0;
  @media (max-width: 650px) {
    margin: 24px 0 46px;
  }
`;
const TextLink = styled(Link)`
  display: flex;
  justify-content: center;
  display: ${(props) => (props.position === "foot" ? "none" : "flex")};
  @media (max-width: 650px) {
    margin-top: 40px;
    margin-bottom: 60px;
    display: ${(props) => (props.position === "foot" ? "flex" : "none")};
    justify-content: center;
    padding: 20px 45px;
    font-size: ${typography.size.s3}px !important;
    width: 100%;
    border: 1px solid #3dae73;
    box-sizing: border-box;
    border-radius: 40px;
  }
`;
// const TextExtend = styled(Text)``;
const Icon = styled(Icons)`
  width: 17px;
  height: 17px;

  margin-top: 6px;
`;
const Head = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 650px) {
    width: 100%;
    flex-direction: column;
    padding-top: 20px;
  }
`;
const Wrapper = styled.div`
  background-color: ${color.primary.blue};
  margin-top: 130px;
  padding: 30px 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 650px) {
    flex-direction: column;
    padding: 0;
    margin-top: 80px;
  }
`;

Stories.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
      icon: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
  primary: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    title: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
  }),
};

Stories.defaultProps = {};
