import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// import { Link } from '../../../components/Link/Link';
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import htmlSerializer from "../../SpaceConntainer/RichtextHelper/CustomText";
import Fade from "react-reveal/Fade";
import { isMobile } from "../../../hooks/checkMobile";

export const HightLight = ({ data }) => {
  if (!data) return null;
  return (
    <Wrapper>
      <Content>
        <Title>
          <Text
            color={color.primary.blue}
            font={typography.type.code}
            weight={typography.weight.regular1}
            tag="h3"
            align="center"
          >
            <Fade bottom disabled={isMobile} distance="10px">
              <RichText
                render={data?.title?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Fade>
          </Text>
        </Title>
        <Description
          color={color.netraul.black100}
          font={typography.type.primary}
          size={typography.size.s3}
          align="center"
          lineHeight={26}
        >
          <Fade bottom disabled={isMobile} distance="10px">
            <RichText
              render={data?.description?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Fade>
        </Description>
        {/* <LearnMore>
          <Fade bottom disabled={isMobile} distance="10px">
            <LinkExtend to="#" weight={typography.weight.bold} textDecoration="underline" color={color.primary.blue}>{data?.link_label?.text}</LinkExtend>
          </Fade>
        </LearnMore> */}
      </Content>
    </Wrapper>
  );
};

HightLight.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      content: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

const Wrapper = styled.div`
  max-width: 1058px;
  margin: 0 auto;
  margin-top: -60px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  @media (max-width: 1300px) and (min-width: 1058px) {
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 1057px) and (min-width: 650px) {
    box-sizing: border-box;
    width: 100%;
    padding: 0 40px;
  }
  @media (max-width: 649px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
`;

const Content = styled.div`
  background-color: ${color.primary.white};
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  @media (max-width: 650px) {
    gap: 8px;
  }
`;

const Title = styled.div`
  margin-top: 70px;
  span {
    white-space: normal;
  }
`;
// const LinkExtend = styled(Link)`
//   text-underline-offset: 5px;
// `
const Description = styled(Text)`
  padding: 0 170px;
  @media (max-width: 1000px) {
    padding: 0;
  }
`;
