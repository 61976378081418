import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { Text } from "../../../components/Text/Text";
import banner from "../../../images/banner.png";
import { color, typography } from "../../../shared/style";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../../components/RichtextHelper/CustomText";
import Fade from "react-reveal/Fade";
// import useApp from '../../../contexts/context'
import { isMobile } from "../../../hooks/checkMobile";
import { redirectPage } from "../../../hooks/redirect";

export const Banner = ({ title, banner, cta }) => {
  // const { isMobile } = useApp()
  return (
    <WrapperSection banner={banner?.url}>
      <Content>
        <TextExtend
          font={typography.type.code}
          tag="h3"
          color={color.primary.white}
          capital="uppercase"
          weight={typography.weight.regular1}
          align="center"
        >
          <Fade bottom duration={2000} disabled={isMobile} distance="10px">
            <RichText render={title?.raw} htmlSerializer={htmlSerializer} />
          </Fade>
        </TextExtend>
        <Fade distance="10px" delay={500} disabled={isMobile}>
          <GroupButton>
            {cta &&
              cta.map((item, i) => (
                <ButtonExtended
                  key={i}
                  onClick={() =>
                    redirectPage({
                      url: item?.button_link?.url,
                      type: item?.button_link?.type,
                      link_type: item?.button_link?.link_type,
                    })
                  }
                  type={i === 0 ? "primary" : "lightblue"}
                >
                  {item?.button_label?.text}
                </ButtonExtended>
              ))}
          </GroupButton>
        </Fade>
      </Content>
    </WrapperSection>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
};

Banner.defaultProps = {
  // title: "Join a global community of entrepreneurs and creators"
  data: {
    primary: {
      banner: {
        url:
          "https://images.prismic.io/draper-startup-house/a7d8ab1e-6550-46be-8c21-34430b8e1272_Copy+of+Academy-Group+1.png?auto=compress%2Cformat",
        alt: null,
        thumbnails: null,
      },
      description: {
        html: "<p>JOIN A GLOBAL COMMUNITY OF ENTREPRENEURS AND CREATORS</p>",
        text: "JOIN A GLOBAL COMMUNITY OF ENTREPRENEURS AND CREATORS",
        raw: [
          {
            type: "paragraph",
            text: "JOIN A GLOBAL COMMUNITY OF ENTREPRENEURS AND CREATORS",
            spans: [],
          },
        ],
      },
    },
  },
};
const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-bottom: 276px;
  @media (max-width: 650px) {
    width: max-content;
    column-gap: 0px;
    row-gap: 19px;
    margin: 0 auto 175px;
    flex-direction: column;
  }
`;
const ButtonExtended = styled(Button)`
  line-height: 26px;
  height: 65px;
  white-space: nowrap;
`;
const TextExtend = styled(Text)`
  padding: 0 20px 28px;
  @media (max-width: 650px) {
    padding-bottom: 40px;
  }
`;
const WrapperSection = styled.div`
  padding-top: 90px;
  box-sizing: border-box;
  display: flex;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  justify-content: center;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  flex-direction: column;
  padding-top: 187px;
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding-top: 142px;
  }
`;
