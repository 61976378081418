import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color, spacing, typography } from "../../../shared/style";
import thumbnails from "../../../images/thumbnail.png";
import { Text } from "../../../components/Text/Text";
import { Button } from "../../../components/Button/Button";
import Fade from "react-reveal/Fade";
import { isMobile } from '../../../hooks/checkMobile';
import { navigate } from "gatsby";

export const LocationItem = ({ image, country, city, types,uid, ...props }) => {
  if (!image || !country || !city || !types) return null;
  return (
    <ItemWrapper
      onClick={()=>{
        navigate(`/${uid}`);
      }}
    >
      <WrapImage image={image} />
      <Descriptions>
        <Title>
          <TextExtend
            tag="h5"
            color={color.dark.dark100}
            font={typography.type.code}
            weight={typography.weight.regular1}
            capital="uppercase"
            lineHeight={36}
          >
            {city}
          </TextExtend>
          <Country
            color={color.dark.dark100}
            font={typography.type.primary}
            size={typography.size.s2}
            lineHeight={22}
          >
            {country}
          </Country>
        </Title>
        <Type>
          {types &&
            types?.map((item, i) => {
              return (
                <TextTag
                  font={typography.type.primary}
                  size={typography.size.s2}
                  lineHeight={22}
                  capital="uppercase"
                  color="rgba(35, 35, 39, 0.8)"
                >
                  {item?.tag_name}
                </TextTag>
              );
            })}
        </Type>
      </Descriptions>
    </ItemWrapper>
  );
};
export const Locations = ({ listItem, ...props }) => {
  const [showMore, setShowMore] = useState(true);
  const [listShow, setListShow] = useState([]);
  const [index, setIndex] = useState(4);

  const loadMore = () => {
    let newIndex = index + 4;
    const newShowMore = newIndex < listItem?.length;
    if (!newShowMore) {
      newIndex = listItem.length;
    }
    setIndex(newIndex);
    setListShow(listItem.slice(0, newIndex));
    setShowMore(newShowMore);
  };
  useEffect(() => {
    let data = listItem;

    let array = [];
    array = data.slice(0, 4);
    if (typeof window !== "undefined" && window.innerWidth < 650) {
      setListShow(array);
    } else {
      setListShow(listItem);
    }
  }, [listItem]);
  return (
    <Wrapper>
      {listShow?.length !== 0 &&
        listShow?.map((item, i) => {
          return (
            <Item key={i}>
              <Fade disabled={isMobile} distance="10px">
                <LocationItem
                  uid={item?.location?.document?.uid}
                  country={item?.location?.document?.data?.country?.text}
                  types={item?.location?.document?.data?.tags}
                  city={item?.location?.document?.data?.city?.text}
                  image={item?.location?.document?.data?.thumbnail?.url ? item?.location?.document?.data?.thumbnail?.url : item?.image}
                />
              </Fade>
            </Item>
          );
        })}
      {showMore && (
        <ButtonShowMore onClick={loadMore} type="primary">
          See more locations
        </ButtonShowMore>
      )}
    </Wrapper>
  );
};

const TextTag = styled(Text)`
  cursor: pointer !important;
  @media (max-width: 1300px) and (min-width: 1000px) {
    font-size: 10px;
  }
  &:after {
    content: "•";
    box-sizing: border-box;
    margin: 0 6px;
  }
  &:last-child {
    &:after {
      content: ""
    }
  }
  @media (max-width: 650px) {
    font-size: 14px !important;
    line-height: 22px;
  }
`

const ButtonShowMore = styled(Button)`
  display: none;
  @media (max-width: 649px) {
    display: block;
    max-width: 100%;
    min-width: 230px;
    margin: 0 auto;
    border: 1px solid ${color.primary.blue};
    border-radius: 30px;
    height: 60px;
    margin-top: 40px;
  }
`;

const Country = styled(Text)`
  cursor: pointer !important;
`
const TextExtend = styled(Text)`
  cursor: pointer !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1100px) {
    font-size: ${typography.size.m4}px !important;
  }
  @media (max-width: 768px) {
    font-size: ${typography.size.l1}px !important;
  }
`;
const Item = styled.div`
  .react-reveal {
    width: 100%;
  }
  &>div:first-child {
    width: 100%;
  }
  padding: 25px 16px;
  display: flex;
  width: 25%;
  box-sizing: border-box;
  @media (max-width: 1300px) and (min-width: 1001px) {
    width: calc((100% - 60px) / 3);
  };
  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 649px) {
    width: 100%;
    padding: 12px 0;
  }
`;
const Type = styled.div`
  display: block;
  letter-spacing: 2.4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Title = styled.div`
  padding-bottom: 10px;
  box-sizing: border-box;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1100px) {
    max-width: 100%;
  }
  @media (max-width: 480px) {
    max-width: 60%;
  }
`;
const ItemWrapper = styled.div`
  cursor: pointer;
  min-height: 344px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: ${spacing.borderRadius.smaller}px;
  @media (max-width: 768px) {
    min-height: 350px;
  }
  @media (max-width: 650px) {
    /* height: auto; */
  }
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 55px rgb(0 0 0 / 22%);
  }
`;
const Wrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 62px;
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 1300px) and (min-width: 1001px) {
    gap: 30px;
  };
  @media (max-width: 649px) {
    flex-direction: column;
    margin-top: 0;
  padding: 0 20px;
  }
`;
const WrapImage = styled.div`
  border-radius: 3px 3px 0px 0px;
  background-position: center;
  background-size: ${(props) => (props.image ? "cover" : "100% 100%")};
  background-repeat: no-repeat;
  height: 65%;
  max-height: 215px;
  background-image: url(${(props) => (props.image ? props.image : thumbnails)});
`;
const Descriptions = styled.div`
  background: white;
  height: 100%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid ${color.primary.blue10};
  /* height: 35%; */
  padding: ${spacing.padding.medium}px;
  box-sizing: border-box;
  display: flex;
  max-height: 150px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
  }
`;

Locations.propTypes = {
  listItem: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      country: PropTypes.shape({
        text: PropTypes.string,
      }),
      city: PropTypes.shape({
        text: PropTypes.string,
      }),
      tag: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    })
  ),
};

Locations.defaultProps = {
  listItem: [
    {
      id: 1,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
    {
      id: 2,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
    {
      id: 3,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
    {
      id: 4,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
    {
      id: 5,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
    {
      id: 6,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
    {
      id: 7,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
    {
      id: 8,
      image: {
        url:
          "https://images.prismic.io/draper-startup-house/6b21c9f0-c9a4-4209-9bec-7e828e4e411b_draper-startup-house-bali-indonesia.jpg?auto=compress%2Cformat",
      },
      country: {
        text: "Bali",
      },
      city: {
        text: "Indonesia",
      },
      tags: {
        text: "Hostel + coworking + cafe",
      },
    },
  ],
};
