import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color, typography } from "../../shared/style";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../containers/SpaceConntainer/RichtextHelper/CustomText";
import Fade from "react-reveal/Fade";
import { isMobile } from "../../hooks/checkMobile";
import { redirectPage } from "../../hooks/redirect"
const TYPE = {
  ventures: "ventures",
  academy: "academy",
  service: "service",
};
export const ThreeInOneSection = (props) => {
  const { type, primary } = props;
  return (
    <Container>
      <Wrapper>
        <ImageWrapper isLeft={primary?.image_position === "left"}>
          <Fade
            distance="10px"
            right={primary?.image_position === "right"}
            left={primary?.image_position === "left"}
            disabled={isMobile}
          >
            <Image box_color={primary?.shadow_color} src={primary?.image?.url} />
          </Fade>
        </ImageWrapper>
        <Contents isLeft={primary?.image_position === "left"}>
          <Fade
            distance="10px"
            left={primary?.image_position === "right"}
            right={primary?.image_position === "left"}
            disabled={isMobile}
          >
            <WrapperTitle isLeft={primary?.image_position === "left"}>
              <Title
                color={primary?.shadow_color}
                isLeft={primary?.image_position === "left"}
                tag="h3"
                weight={typography.weight.regular1}
                capital="uppercase"
              >
                <RichText
                  render={primary?.title?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Title>
            </WrapperTitle>
            <Info bgColor={primary?.background_color_content}>
              <Description
                color={color.netraul.black100}
                tag="h4"
                capital="uppercase"
                weight={typography.weight.regular}
              >
                {/* <RichText
                  render={primary?.description?.raw}
                  htmlSerializer={htmlSerializer}
                /> */}
                {primary?.description?.text}
              </Description>
              <ContentWrapper>
                <Content
                  lineHeight={26}
                  font="Oxygen"
                  weight={typography.weight.regular}
                  color={color.netraul.black100}
                >
                  <RichText
                    render={primary?.content?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Content>
              </ContentWrapper>
              <Btn
                type="primary"
                onClick={() =>
                  redirectPage({
                    url: primary?.button_link?.url,
                    type: primary?.button_link?.type,
                    link_type: primary?.button_link?.link_type
                  })
                }
              >
                Learn more
              </Btn>
            </Info>
          </Fade>
        </Contents>
      </Wrapper>
    </Container>
  );
};
const WrapperTitle = styled.div`
  margin-bottom: 21px;
  ${(props) =>
    props.isLeft &&
    `
      // margin-right: 20%;
      text-align: right;
    `}
  @media (max-width: 1300px ) {
    margin-right: 0;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin: 10px 0;
    text-align: left;
  }
`;
const Container = styled.div`
  height: 760px;
  margin-top: 150px;
  padding: 20px;
  @media (max-width: 1000px) {
    height: fit-content;
    margin-top: 90px;
  }
`;
const Wrapper = styled.div`
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 95%;
  }
  @media (max-width: 1000px) {
    max-height: fit-content;
    min-height: 70vw;
  }
  @media (max-width: 500px) {
    max-height: fit-content;
    min-height: 70vw;
  }
  max-height: 760px;
  position: relative;
  height: 100%;
`;
const Info = styled.div`
  background-color: ${(props) => props.bgColor ? props.bgColor : color.secondary.bgrYellow};
  max-width: 418px;
  max-height: 502px;
  width: 100%;
  height: 100%;
  padding: 63px;
  @media (max-width: 650px) {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    max-height: fit-content;
    padding: 20px;
    padding-top: 40px;
  }
`;
const Description = styled(Text)`
  letter-spacing: -0.015em;
`;
const Content = styled(Text)``;
const ContentWrapper = styled.div`
  margin: 20px 0;
`;
const Btn = styled(Button)`
  border-radius: 40px;
  height: 65px;
  padding: 20px 45px;
  font-family: ${typography.type.primary};
  line-height: 26px;
  @media (max-width: 650px) {
    margin-bottom: 26px;
  }
`;
const Title = styled(Text)`
  ${(props) => {
    return props.isLeft && `text-align: right;`;
  }}
  @media (max-width: 1100px) {
    font-size: 80px !important;
  }
  @media (max-width: 1000px) {
    font-size: 65px !important;
  }
  @media (max-width: 730px) {
    font-size: 62px !important;
  }
  @media (max-width: 650px) {
    text-align: left;
  }
`;
const Contents = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  ${(props) => (props.isLeft ? `right: 0` : `left: 0`)};
  @media (max-width: 650px) {
    position: relative;
  }
`;
const ImageWrapper = styled.div`
  position: absolute;
  width: 63vw;
  max-width: 874px;
  ${(props) => (props.isLeft ? `left: 0` : `right: 0`)};
  top: 0;
  z-index: 0;
  @media (max-width: 650px) {
    width: 100%;
    position: relative;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
  box-shadow: ${(props) => props.box_color ? `32px -32px 1px ${props.box_color}` : "black"};

  @media (max-width: 650px) {
    box-shadow: ${(props) =>props.box_color ? `10px -10px 1px ${props.box_color}` : "black"};
  }
`;
ThreeInOneSection.propTypes = {
  primary: PropTypes.shape({
    content: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
    title: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
    description: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
    image: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  type: PropTypes.oneOf(Object.values(TYPE)),
};

ThreeInOneSection.defaultProps = {
  type: TYPE.ventures,
};
